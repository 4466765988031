<script>
import { onMounted, toRefs, reactive } from 'vue'
import { useStore } from 'vuex'
import { ipqsIsPhoneValid } from '@/util/validations'
import { maska } from 'maska'

const stepName = 'Phone'

export default {
  name: stepName,

  directives: {
    maska
  },

  setup(_, {emit}) {
    const store = useStore()

    onMounted(() => {
      store.commit('SET_IS_LOADING', false)
    })

    const state = reactive({
      phone: {
        value: '',
        error: false
      }
    })

    const setAnswer = async () => {
      state.phone.error = await ipqsIsPhoneValid(state.phone.value)
      if (state.phone.error) return

      const answer = {field: 'phone', value: state.phone.value.replace(/[()\-\s]+/g, "")}
      store.commit('SET_FIELD', answer)
      emit('next-step', stepName)
    }

    const handler = () => {
      state.phone.error = false
    }

    return {
      ...toRefs(state),
      setAnswer,
      handler
    }
  }
}
</script>

<template>
  <div>
    <div class="row">
      <div class="box phone-box">
        <input
          type="text"
          v-maska="'(###) ### ####'"
          class="form-input phone-input"
          :class="{'phone-error' : phone.error}"
          placeholder="Phone"
          v-model="phone.value"
          @input="handler"
        >

        <p class="muted-text">By clicking the Submit Button, I agree to the consents below the button.</p>

        <button
          class="main-button phone-button mt-sm"
          @click="setAnswer"
        >
          View My Results
        </button>
      </div>
    </div>

    <div class="row">
      <div class="box">
        <p class="muted-text tcpa">
          By clicking "View My Results", I provide my express written consent via electronic
          signature authorizing American First Life and one or more Insurance
          brokerages,
          <a href="/partners" target="_blank">
            their agents and marketing partners
          </a>
          to contact me about life insurance and other related insurance products and
          services to the number and email address I provided (including any wireless
          number). I further expressly consent to receive telemarketing emails, calls,
          text messages, pre-recorded messages, and artificial voice messages via an
          autodialed phone system, even if my telephone number is a mobile number that
          is currently listed on any state, federal or corporate “Do Not Call” list. I
          understand that my consent is not a condition of purchase of any goods or
          services and that standard message and data rates may apply. By clicking the
          submit button, I confirm I have read and agreed to this website's
        </p>

        <p class="muted-text tcpa">
          By clicking
          “View My Results”, I also agree I have read and agreed to this websites
          <a href="/terms-of-use" target="_blank">
            Terms of Conditions
          </a>
          and
          <a href="/privacy-policy" target="_blank">
            Privacy Policy,
          </a> which include a mandatory arbitration and class action waiver provision.
        </p>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '../../../assets/styles/component_styles/questionnaire/phone.scss';
</style>